import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "primeicons/primeicons.css";
import mitt from "mitt";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import excel from "vue-excel-export";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css";
import ProgressBar from "primevue/progressbar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Row from "primevue/row";
import ToastService from "primevue/toastservice";
import Card from "primevue/card";
import InputSwitch from "primevue/inputswitch";
import Toast from "primevue/toast";
import Calendar from "primevue/calendar";
import Carousel from "primevue/carousel";
import Tree from "primevue/tree";
import InputNumber from "primevue/inputnumber";
import Paginator from "primevue/paginator";
import ProgressSpinner from "primevue/progressspinner";
import VueGtag from "vue-gtag";

window.api = axios.create({
  baseURL: "https://api.cer-ecsf.fr/api",
});

const emitter = mitt();

let app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.use(excel);
app.use(BootstrapVue3);
app.use(store);
app.use(router);
app.use(PrimeVue);
app.component("Button", Button);
app.component("Dropdown", Dropdown);
app.component("ProgressBar", ProgressBar);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Dialog", Dialog);
app.component("MultiSelect", MultiSelect);
app.component("Checkbox", Checkbox);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Card", Card);
app.component("InputSwitch", InputSwitch);
app.component("Toast", Toast);
app.component("Calendar", Calendar);
app.component("Carousel", Carousel);
app.component("Tree", Tree);
app.component("InputNumber", InputNumber);
app.component("Paginator", Paginator);
app.component("ProgressSpinner ", ProgressSpinner);
app.use(ToastService);
app.use(VueGtag,{
  config: { id: "G-YG44D09WYR" }
});

app.mount("#app");
