<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["getUtilisateurs"]),

    deconnexion() {
      this.$store.commit("setUser", null);
      this.$store.commit("setToken", null);
      localStorage.removeItem("user_info");
      localStorage.removeItem("user_access");
    },

    print() {
      console.log(this.$store.state.user);
    },
  },

  computed: {
    utilisateurs() {
      return this.$store.state.utilisateurs;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffff;
  background-color: lightblue;
  opacity: 0.7;
}

#logo {
  width: 30x;
  height: 30px;
}

li {
  float: left;
  background-color: lightblue;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  background-color: lightblue;
}

li a:hover {
  background-color: #fbfbfb;
}

.active {
  background-color: #ffffff;
  border-left: 1px black solid;
  border-right: 1px black solid;
  border-top: 1px black solid;
  background-color: lightblue;
}
</style>
