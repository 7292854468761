<template>
   <div id="nav">
    <Navbar />
  </div>
  <router-view/>
</template>

<script>
import Navbar from '@/components/NavBar.vue';

export default {
  components:{
        Navbar
  },
  mounted() {
    this.checkLocalStorage();
  },
  methods: {
    checkLocalStorage(){
      if(
        this.$route.name === "index"
        && this.$route.name === "Connexion"
        && this.$route.name === "Inscription"
      ) {
        this.$store.commit('setUser', null);
        this.$store.commit('setToken', null);
      }
      if(localStorage.getItem('user_access') != null && localStorage.getItem('user_info') != null){
        this.$store.commit('setUser', JSON.parse(localStorage.getItem('user_info')));
        this.$store.commit('setToken', JSON.parse(localStorage.getItem('user_access')));
      }
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 0rem;

}

#apptest {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 2rem;


}

#nav {
  padding-bottom: 0rem;
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
